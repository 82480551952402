import React from "react"

import Layout from "../components/layout"

const NotAllowPage = () => (
  <Layout>
    <h1>NOT ALLOW</h1>
    {/* <p>You just hit a route that doesn&#39;t exist... the sadness.</p> */}
  </Layout>
)

export default NotAllowPage
